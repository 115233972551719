.cards {
  width: 100%;
  padding: 2rem;
  background: #fcfbfb;
  /* margin-top: 130px; */
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1520px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  margin-top: 10px;
  margin-left: 10px;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 60%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

/* .cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: white;
  box-sizing: border-box;
} */

.cards__item__img {
  position: absolute;
  padding: 10px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  padding: 10px;
  transform: scale(1);
}

.cards__item__info {
  padding: 20px 30px 30px;
  color: #252e48;
}

.cards__item__text {
  color: #252e48;
  font-size: 1rem; /* Adjust font size as needed */
  word-wrap: break-word; /* Allow long words to wrap */
  line-height: 30px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .cards__item__text {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .cards__items{
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .cards__item__img {
    position: absolute;
    padding: 10px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.cards__container{
  width: 100%;
  justify-content: center;
  align-items: center;
}


  .cards{
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}