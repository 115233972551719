.navbar {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: white;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 100%;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    /* margin-left: 20px; */
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;

  }
  
  .nav-links {
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
    color: #b71414;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }

    .fa-bars::before{
      color: #242222;
      margin-right: 10px;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -120%;
      opacity: 1;
      transition: all 0.5s ease;
      color: black;
    }
  
    .nav-menu.active {
      position: fixed; /* Fixes the position relative to the viewport */
      height: 100%; /* Covers the entire height of the viewport */
      width: 100%;
      display: flex;
      background: #242222;
      top: 80px;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      margin: 0 auto;
      justify-content: center;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      color: white;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: black;
      font-size: 2rem;
      margin-right: 10px;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }

    .navbar {
      height: 80px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      position: fixed;
    }

    .nav-item{
      height: 10%;
      width: 90%;
      display: flex;
      margin: 10px;
      /* justify-content: space-between; */
      align-items: center;
    }

    
  }

  .logo{

      width: '50px';
      height: 'auto';
      border-radius: '10px';
      box-shadow: '0 2px 4px rgba(0, 0, 0, 0.1)';

  }

  .fa-bars::before{
    color: #242222;
  }

  .fa-bars::after{
    color: #242222;
  }

  @media (max-width: 768px) {
    .navbar {
      position: fixed;
      top: 0;
      height: 80px;
    }

    .logo{
      margin-top: -15px;
      margin-left: 5px;
    }
  }

  