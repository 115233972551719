video {
    object-fit: fill;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container{
    background: url('/public/images/cropped.jpeg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;  
}

.hero-container > h1 {
    color: #fff;
    font-size: 120px ;
    margin-top: -100px;
    z-index: 2; 
}

.hero-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px); /* Adjust the blur amount as needed */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    z-index: 1; /* Ensure the blur is behind the content */
  }

.hero-container > p {
    margin-top: 8px;
    color: #FDFDFD;
    font-size: 32px ;
    z-index: 2; 
}

.hero-btns{
    margin-top: 32px;
    z-index: 2; 
}

.hero-btns .btn{
    margin: 6px;
}

.fa-play-circle{
    margin-left: 4px;
}

@media screen and (max-width: 960px) {
    .hero-container{
        background-position: right center;
    }
    .hero-container > h1 {
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-container > p {
        font-size: 30px ;
        text-align: center;
    }
    
    .btn-mobile{
        display : block;
        text-decoration: none;
    }

    .btn{
      width: 100%;  
    }
}


/* Button */
:root {
    --primary: #fff;
}

.btn{
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary{
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}
.btn--outline{
    background-color: transparent;
    color: var(--primary);
    padding: 8px 20px;
    border: 2px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium{
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover,
.btn--medium:hover{
    background: #fff;
    color: #242424;
    transition: 250ms;
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .animate-right-to-center {
    animation: slideInFromRight 2s ease-out forwards;
  }

  .animate-left-to-center {
    animation: slideInFromLeft 2s ease-out forwards;
  }