.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    /* text-align: center; */
  
    /* margin-bottom: 24px; */
    /* padding: 24px; */
    /* color: #fff; */
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    text-align: center;
    width: 400px;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
    
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    max-width: 1000px;
    margin: 40px auto 0 auto;
    /* border: 1px solid; */
  }

  .social-media-wrap > small{
    justify-content: center;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
    padding-left: 20px;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }

    .social-logo{
      align-items: center;
    }
    
  }
  
  @media screen and (max-width: 768px) {
    .footer-logo {
      display: flex;
      justify-content: start; /* Horizontally center the content */
      align-items: center; /* Vertically center the content */
    }

    .social-logo img {
      width: 40px; /* Example width */
      height: auto; /* Maintains aspect ratio */
      border-radius: 10px; /* Example border radius */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Example box shadow */
      margin-right: 18px;
    }

    .website-rights{
      margin-right: 20px;
      margin-bottom: 40px;
    }

    .social-icons{
      margin-bottom: 30px;
    }
  
  }

  .footer-logo {
    display: flex;
    justify-content: start; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
  }

  .social-logo img {
    width: 50px; /* Example width */
  }

  .footer-footer{
    width: 100%;
    color: white;
    display: flex;
    justify-content: end;
    font-style: italic;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 10px;
    /* border: 1px solid red; */
    /* background-color: #b1b1b1; */
    padding: 10px;
  }

  